import { inject }                    from 'aurelia-framework';
import { ExternalTablesRepository }  from 'modules/core/services/external-tables-repository';
import { HighwaysRepository }        from 'modules/management/concessions/concessions-tree/highways/services/repository';
import { LotInterventionSubsection } from 'modules/management/models/lot-intervention-subsection';
import { ConcessionsRepository }     from 'modules/management/specifications/concessions/services/repository';

@inject(ConcessionsRepository, HighwaysRepository, ExternalTablesRepository)
export class LotInterventionSubsectionFormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param concessionsRepository
     * @param highwaysRepository
     * @param externalTablesRepository
     */
    constructor(concessionsRepository, highwaysRepository, externalTablesRepository) {
        this.concessionsRepository    = concessionsRepository;
        this.highwaysRepository       = highwaysRepository;
        this.externalTablesRepository = externalTablesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LotInterventionSubsection}
     */
    model() {
        let model = new LotInterventionSubsection();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.concession_id = {
            type:         'select2',
            key:          'concession_id',
            label:        'form.field.concession',
            size:         6,
            required:     false,
            remoteSource: () => this.concessionsRepository.allByLoggedUser(),
            observers:    [
                (newValue, oldValue) => {
                    this.highway_id.instance.disable(!newValue);

                    if (newValue) {
                        return this.concessionsRepository.externalHighways(newValue).then((response) => {
                            this.highway_id.options = response;
                            this.highway_id.instance.enable(response.length);
                        });
                    }
                },
            ],
        };

        this.highway_id = {
            type:         'select2',
            key:          'highway_id',
            label:        'form.field.highway-road',
            size:         6,
            required:     false,
            remoteSource: () => {
                return viewModel.model.concession_id
                    ? this.concessionsRepository.externalHighways(viewModel.model.concession_id)
                    : Promise.resolve([]);
            },
            attributes:   {
                disabled: !viewModel.model.concession_id,
            },
            observers:    [
                (newValue, oldValue) => {
                    const highway = this.highway_id.options.find(option => option.id === newValue);

                    viewModel.model.highway_code = highway ? highway.code : null;
                },
            ],
        };

        this.external_subsection_ids = {
            type:               'acbin-duallistbox',
            key:                'external_subsection_ids',
            label:              'form.field.subsections',
            size:               12,
            remoteSource:       () => this.externalTablesRepository.searchAllSubsections(),
            additional_filters: [
                {
                    model:               viewModel.model,
                    model_field:         'highway_code',
                    remote_source_field: 'ae_id',
                },
            ],
        };

        return [
            [this.concession_id, this.highway_id],
            [this.external_subsection_ids],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
