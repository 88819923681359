import { inject }                           from 'aurelia-framework';
import { LotInterventionSpecification }     from 'modules/management/models/lot-intervention-specification';
import { SpecificationRevisionsRepository } from 'modules/management/specifications/specifications/specification-revisions/services/repository';

@inject(SpecificationRevisionsRepository)
export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {
        //
    };

    /**
     * Constructor
     *
     * @param specificationsRevisionsRepository
     */
    constructor(specificationsRevisionsRepository) {
        this.specificationsRevisionsRepository = specificationsRevisionsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {LotInterventionSpecification}
     */
    model() {
        let model = new LotInterventionSpecification();

        model.assign(this.modelDefaults);

        return model;
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @param readonly
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {
        this.specification_revision_id = {
            type:         'select2',
            key:          'specification_revision_id',
            label:        'form.field.specification-book',
            size:         4,
            remoteSource: () => this.specificationsRevisionsRepository.all(),
            attributes:   {
                disabled: readonly,
            },
        };

        this.start_date = {
            type:            'material-ui-date-picker',
            key:             'start_date',
            label:           'form.field.start-date',
            size:            4,
            required:        false,
            valueConverters: [
                { name: 'dateFormat' },
            ],
            attributes:      {
                disabled: readonly,
            },
        };

        this.finish_date = {
            type:            'material-ui-date-picker',
            key:             'finish_date',
            label:           'form.field.finish-date',
            size:            4,
            required:        false,
            valueConverters: [
                { name: 'dateFormat' },
            ],
            attributes:      {
                disabled: readonly,
            },
        };

        return [
            [this.specification_revision_id, this.start_date, this.finish_date],
        ];
    }

    /**
     * Returns buttons schema
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    globalSchema(viewModel, readonly = false) {
        this.cancelButton = {
            type:       'button',
            label:      readonly ? 'form.button.close' : 'form.button.cancel',
            action:     () => viewModel.dialogController.cancel(),
            attributes: {
                class: 'btn btn-link btn-light legitRipple',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      viewModel.create ? 'form.button.clear' : 'form.button.reverse-changes',
            action:     () => viewModel.resetForm(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.submitButton = {
            type:       'submit',
            label:      'form.button.save',
            action:     () => viewModel.submit(),
            hidden:     readonly,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                attributes: {
                    class: 'icon-floppy-disk',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.cancelButton,
                this.clearButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

}
