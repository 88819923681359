import { DialogController }                         from 'aurelia-dialog';
import { bindable, inject }                         from 'aurelia-framework';
import { PLATFORM }                                 from 'aurelia-pal';
import { BaseFormViewModel }                        from 'base-form-view-model';
import { FormSchema }                               from 'modules/management/concessions/concessions-tree/lot-interventions/lot-interventions-specifications/form-schema';
import { LotInterventionsSpecificationsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/lot-interventions-specifications/services/repository';
import { AppContainer }                             from 'resources/services/app-container';

@inject(AppContainer, DialogController, LotInterventionsSpecificationsRepository, FormSchema)
export class CreateLotInterventionSpecificationModal extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'management.concessions.concessions.lot-interventions.specifications.creation-form';

    @bindable alert = {};
    model           = {};
    schema          = {};
    create          = true;

    lotIntervention;

    @bindable settings = {
        title:   'form.title.create-new-record',
        size:    'modal-lg',
        buttons: false,
    };

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogController
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, dialogController, repository, formSchema) {
        super(appContainer);

        this.dialogController = dialogController;
        this.repository       = repository;
        this.formSchema       = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/concessions/concessions-tree/lot-interventions/lot-interventions-specifications/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.concessions.concessions.lot-interventions.specifications.manage',
            'management.concessions.concessions.lot-interventions.specifications.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.lotIntervention = params.lotIntervention.id;

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);
        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.lotIntervention, this.model)
            .then((response) => {
                this.handleResponse(response);
                this.initialModel.assign(this.model);

                if (response.status === true) {
                    this.dialogController.ok();
                }
            })
            .catch((error) => console.error(error));
    }

}
